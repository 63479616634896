import { types,flow  } from "mobx-state-tree"
import axios from 'axios';


const VersionModel = types.model({
    name: types.string /* v170 */,
    /* id: types.string  *//* 1.7.0 */
    installDate: types.string /* 2020.06.10*/,
    desc: types.string /* ["Personalien/XXX - formula fix"]" , "*New Abklarung-Section 10"] */ 
  
   
}).actions(self => ({

    update: flow (function*() {
         self.desc="Updating...";
        const {
            data: result,
            status: responseStatus,
            statusText
          } = yield axios
            .post('/api/AppHost/version_update',{
               name: self.name
            })
            .then(response => response)
            .catch(error => error.response);
    
            //setTimeout(function(){
            //    self.status =isNaN(result)?2:result;

            self.desc=result?'Update successful.':'Update failed';
            return result;
    }),
    remove: flow (function*() {
        self.desc="Removing...";
       const {
           data: result,
           status: responseStatus,
           statusText
         } = yield axios
           .post('/api/AppHost/version_remove',{
              name: self.name
           })
           .then(response => response)
           .catch(error => error.response);
   
           //setTimeout(function(){
           //    self.status =isNaN(result)?2:result;

           self.desc=result?'Removal successful.':'Removal failed';
           return result;
   }),
}))

export default VersionModel;