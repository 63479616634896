import React, { useEffect } from 'react';
import { observer } from 'mobx-react';



function Initialization(props) {
  
  return (
    <div className="initialization ">
        <h2>Initializing...</h2>
      {/*   <p className="mini">Checking application state...</p> */}
       
    </div>
  );
}

//export default Initialization;
export default observer(Initialization);
