import { types } from "mobx-state-tree"
import ApplicationModel from './ApplicationModel'
import UserModel from './UserModel'
const RootStore = types
    .model({
        application: ApplicationModel,
        user: UserModel
    })
    .actions(self => ({
       /*  addTodo(id, name) {
            self.todos.set(id, Todo.create({ name }))
        } */
    }))


    export default RootStore;