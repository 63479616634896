import React,{useState} from 'react';



function Installer(props) {

//websiteUrl ,  sourceUrl, dbHostName, dbUser,dbPassword,emailAddress

const [websiteUrl, setwebsiteUrl] = useState(document.location.toString());
const [sourceUrl, setsourceUrl] = useState('http://source.exactconstruct.synology.me');//https://fakt-repo.exco-apps.ch
const [dbHostName, setdbHostName] = useState('localhost');
const [dbPort, setDbPort] = useState('3306');
const [dbUser, setdbUser] = useState('root');
const [dbPassword, setdbPassword] = useState('');
const [emailAddress, setemailAddress] = useState('info@mail.com');
const [adminPassword, setadminPassword] = useState('');

const [installStatus, setinstallStatus] = useState('ready')

const [connectionStatus, setConnectionStatus] = useState('')

const t = props.t;

const requestInstall=()=>{
    
    setinstallStatus('installing');
    setConnectionStatus('testing');
    props.application.testConnection(dbHostName,dbPort,dbUser,dbPassword)
    .then((response)=>{
       if(response==true) {
        setConnectionStatus('success');
            props.application.install(websiteUrl,sourceUrl,dbHostName,dbPort,dbUser,dbPassword,emailAddress,adminPassword)
            .then((install_response)=>{
                if(install_response==true) setinstallStatus('installed');
                else  setinstallStatus('failed');
            })
       }
       else  setConnectionStatus('failed');
    })

    
    
}
const tryLogin=()=>{
    props.application.checkState();
}

  return (
      <>
    {installStatus=='ready' && 
    <div className="installer">
        <h1>{t('install.title')}
        <p className="mini">{t('install.instructions')}</p></h1>
        <form onSubmit={e => requestInstall() }>
            <div className="form-group">
                <label htmlFor="websiteUrl">{t('install.website_url')}
                <p className="mini">{t('install.website_url_about')}</p>
                </label>
                <input type="text" className="form-control" value={websiteUrl} id="websiteUrl" placeholder="https://localhost" onChange={e => setwebsiteUrl(e.target.value)} />
            </div>

            <div class="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="dbHostName">{t('install.db_hostname')}</label>
                    <input type="text" className="form-control" value={dbHostName} id="dbHostName" placeholder={t('install.db_hostname')}  onChange={e => setdbHostName(e.target.value)} />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="dbPort">{t('install.db_port')}</label>
                    <input type="text" className="form-control" value={dbPort} id="dbPort" placeholder="Port"  onChange={e => setDbPort(e.target.value)} />
                </div>
            </div>
            <div class="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="dbUser">{t('install.db_admin_username')}</label>
                    <input type="text" className="form-control" value={dbUser} id="dbUser" placeholder={t('install.db_admin_username')} onChange={e => setdbUser(e.target.value)}/>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="dbPassword">{t('install.db_admin_password')}</label>
                    <input type="password" className="form-control" value={dbPassword} id="dbPassword" placeholder={t('install.db_admin_username') +' '+ t('install.db_admin_password')} onChange={e => setdbPassword(e.target.value)} />
                    
                </div>
                <p className="mini">{t('install.db_about')}</p>
            </div>

            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="emailAddress">{t('install.admin_email')}
                    </label>
                    <input type="email" className="form-control" value={emailAddress} id="emailAddress" placeholder={t('install.admin_email')}  onChange={e => setemailAddress(e.target.value)} />
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="adminPassword">{t('install.db_admin_password')}</label>
                    <input type="password" className="form-control" value={adminPassword} id="adminPassword" placeholder={t('Account Admin') +' '+ t('install.db_admin_password')} onChange={e => setadminPassword(e.target.value)} />
                </div>
            </div>

            <div className='form-group float-right mb-5 mt-3'>
                <button type="submit" className="btn btn-default bg-gradient-primary border-primary text-white" >{t('install.btn_install')}</button>
            </div>
           
            </form>
    </div>}
    {installStatus=='installing' && 
     <div className="installer">
        <h1>{t('install.installing_title')}
        <p className="mini">
        {connectionStatus=='testing' && t('install.install_testdb')}
        {connectionStatus=='failed' && t('install.install_testdb_fail')}
        {connectionStatus=='success' &&  t('install.install_installing')}
        </p></h1>
        {connectionStatus=='failed' &&
            <div className='form-group float-right mb-5 mt-3'>
                <button type="submit" className="btn btn-default bg-gradient-primary border-primary text-white" onClick={e=> setinstallStatus('ready')} >{t('install.install_return')}</button>
            </div>
        }
     </div>
    }
    { installStatus=='installed' && 
        <div className="installer">
        <h1>{t('install.install_success_title')}
        <p className="mini"> {t('install.install_success_about')}</p></h1>
        <div className='form-group float-right mb-5 mt-3'>
                <button type="submit" className="btn btn-default bg-gradient-primary border-primary text-white" onClick={e => tryLogin() }>{t('install.install_login')}</button>
            </div>
        </div>
    }{ installStatus=='failed' && 
        <div className="installer">
        <h1>{t('install.install_failed_title')}</h1>
        <p className="mini">{t('install.install_failed_about')}</p>
       
        </div>
    }
    </>
  );
}

export default Installer;
