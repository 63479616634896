import React,{useState} from 'react';



function Error(props) {


  return (
      <>
   
        <div className="installer">
        <h1>Application Error </h1>
        <p className="mini">Error Code {props.code}. Please contact info@exact-construct.ch </p>
       
        </div>
   
    </>
  );
}

export default Error;
