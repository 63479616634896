import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

function VersionManager(props) {
  const app = props.application;
  const installed_versions = app.versions;
  const available_versions = app.availableVersions;
  const [repoBusy, setRepoBusy] = useState(false);
  const t = props.t;

  
  const sortedVersion = installed_versions.sort((a,b)=>{
    const isReversed = (a.name < b.name) ? 1: -1;
    return isReversed
  })
  console.log(sortedVersion);
  
  const updateFromRepo = () => {
    setRepoBusy(true);
  };

  const backToMain = () => {
    app.setStatus(1); // login screen
  };

  const installVersion = (e, version) => {
    e.preventDefault();
    setRepoBusy(true);
    version.install().then(() => {
      app.getInstalledVersions();
      setRepoBusy(false);
    });
  };
  const updateVersion = (e, version) => {
    e.preventDefault();
    setRepoBusy(true);
    version.update().then(() => {
      // app.getAvailableVersions();
      app.getInstalledVersions();
      setRepoBusy(false);
    });
  };
  const removeVersion = (e, version) => {
    e.preventDefault();
    setRepoBusy(true);
    version.remove().then(() => {
      // app.getAvailableVersions();
      app.getInstalledVersions();
      setRepoBusy(false);
    });
  };

  const isUpdateable = (v) => {
    if (
      available_versions.length > 0 &&
      available_versions.find(
        (a) =>
          a.name === v.name && parseInt(a.releaseDate) > parseInt(v.installDate)
      )
    )
      return true;

    return false;
  };
  const isNotInstalled = (v) => {
    if (
      installed_versions.length === 0 ||
      installed_versions.findIndex((a) => a.name === v.name) === -1
    )
      return true;
    else return false;
  };

  const [zipFileInfo, setZipFileInfo] = useState({ name: "", size: "0KB" });

  const [selectedFile, setSelectedFile] = useState(null);
  const handleInputChange = (event) => {
    if (event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);

      var fsize=event.target.files[0].size;
      var fsz="";
      if(fsize> 1024000) fsz=(fsize/1000000.00).toFixed(2)+'MB';
      else  if(fsize> 102400) fsz=(fsize/1000.00).toFixed(2)+'KB';
      else fsz=fsize+'Bytes';
      setZipFileInfo({
        name: event.target.files[0].name,
        size: fsz,
      });
    }else {
      setSelectedFile(null);
      setZipFileInfo({name:'',size:'0KB'})
    }
  };

  const [zipFileLabel, setZipFileLabel] = useState(t("versions.selectzip"));

  useEffect(() => {
    if (zipFileInfo.name == "") setZipFileLabel(t("versions.selectzip"));
    else setZipFileLabel(zipFileInfo.name + " - " + zipFileInfo.size);
  }, [zipFileInfo]);

  const installZipPackage = () => {

    if(selectedFile==null){
      alert(t("versions.selectzip"));
      return;
    }

    setRepoBusy(true);
    app.installFromZip(selectedFile).then((r) => {

      app.getInstalledVersions();
      setRepoBusy(false);
    });


   
  };

  return (
    <div className="initialization " style={{ width: "80vw", height: "80vh" }}>
      <h2>{t("versions.title")}</h2>
      {/*   <p className="mini">Checking application state...</p> */}

      <form>
        <div className="row">
          <div className="form-group mb-5 col-sm">
            <label htmlFor="exampleInputPassword1">
              {t("versions.list_title_installed")}
            </label>
            <div className="col-sm-12 m-0">
              <table className="table table-sm table-striped table-bordered">
                <thead>
                  <tr >
                    <th>{t("versions.col_name")}</th>
                    <th>{t("versions.col_date_installed")}</th>
                    <th>{t("versions.col_description")}</th>
                    <th>{t("versions.col_action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedVersion.map((v) => (
                    <tr key={v.name}>
                      <td>{v.name}</td>
                      <td>{v.installDate}</td>
                      <td>{v.desc}</td>
                      <td>
                        {" "}
                        {isUpdateable(v) && (
                          <>
                            <button
                              className="btn btn-sm btn-warning"
                              onClick={(e) => updateVersion(e, v)}
                            >
                              {t("versions.btn_update")}
                            </button>
                            <span>&nbsp;</span>
                          </>
                        )}
                        <button
                          className="btn btn-sm btn-warning"
                          onClick={(e) => removeVersion(e, v)}
                        >
                          {t("versions.btn_remove")}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="form-group mb-5 col-sm-6">
            <label htmlFor="exampleInputPassword1">{t("versions.installzip")}</label>
            <div className="login-button-row row">
              <label
                htmlFor="upload_file"
                className="form-control form-control-flat col-sm-9 m-0"
              >
                <span className="fz-400">{zipFileLabel}</span>
                <input
                  type="file"
                  className="d-none"
                  id="upload_file"
                  name="upload_file"
                  accept=".zip"
                  onChange={handleInputChange}
                />
              </label>
              <button
                type="button"
                disabled={repoBusy}
                className="col-sm-3 btn btn-default bg-gradient-primary border-primary text-white"
                onClick={(e) => installZipPackage()}
              >
                {t("versions.btn_install")}{" "}
                {repoBusy && <span className="busy-icon">.</span>}
              </button>
            </div>
          </div>
        </div>

        <div className="float-right row">
          <button
            type="button"
            className="float-right btn btn-default bg-gradient-primary border-primary text-white"
            disabled={repoBusy}
            onClick={(e) => backToMain()}
          >
            {t("versions.btn_return")}
            {repoBusy && <span className="busy-icon">.</span>}
          </button>
        </div>
      </form>
    </div>
  );
}

//export default Initialization;
export default observer(VersionManager);
