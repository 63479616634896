import { types,flow  } from "mobx-state-tree"
import axios from 'axios';


const AvailableVersionModel = types.model({
    name: types.string /* v170 */,
    /* id: types.string  *//* 1.7.0 */
    releaseDate: types.string /* 2020.06.10*/,
    desc: types.string
  
   
}).actions(self => ({

    install: flow (function*() {
         self.desc="Installing...";
        const {
            data: result,
            status: responseStatus,
            statusText
          } = yield axios
            .post('/api/AppHost/version_install',{
               name: self.name
            })
            .then(response => response)
            .catch(error => error.response);
    
            //setTimeout(function(){
            //    self.status =isNaN(result)?2:result;

            self.desc=result?'Installation successful.':'INstallation failed';
            return result;
    }),
}))

export default AvailableVersionModel;