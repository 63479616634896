import { types,flow  } from "mobx-state-tree"
import axios from 'axios';
import VersionModel from './VersionModel'
import AvailableVersionModel from "./AvailableVersionModel";
import {useTranslation} from "react-i18next";

const ApplicationModel = types.model({
    name: types.string,
    status: types.optional(types.number, -1) ,
    versions: types.array(VersionModel),
    availableVersions: types.array(AvailableVersionModel),
    websiteUrl: types.string,
    sourceUrl: types.string,
    language: types.string,

    
   
}).actions(self => ({

    setStatus(statusId) {
        self.status = statusId;
    },
    setLanguage(code) {
        self.language = code;
        localStorage.setItem("lang",code);
         
    },
    checkState: flow (function*() {
         
        const {
            data: userObject,
            status: responseStatus,
            statusText
          } = yield axios
            .get('/api/AppHost/checkState')
            .then(response => response)
            .catch(error => error.response);
    
            //setTimeout(function(){
                self.status =isNaN(userObject)?1:userObject;
    }),

    testConnection: flow (function*( dbHostName,dbPort, dbUser,dbPassword) {
         
        const {
            data: result,
            status: responseStatus,
            statusText
          } = yield axios
            .post('/api/AppHost/testConnection',{
                 dbHostName,dbPort, dbUser,dbPassword
            })
            .then(response => response)
            .catch(error => error.response);
    
            //setTimeout(function(){
            //    self.status =isNaN(result)?2:result;

            return result;
    }),

    install: flow (function*(websiteUrl ,  sourceUrl, dbHostName,dbPort, dbUser,dbPassword,emailAddress,adminPassword) {
         
        const {
            data: result,
            status: responseStatus,
            statusText
          } = yield axios
            .post('/api/AppHost/install',{
                websiteUrl ,  sourceUrl, dbHostName,dbPort, dbUser,dbPassword,emailAddress,adminPassword
            })
            .then(response => response)
            .catch(error => error.response);
    
            //setTimeout(function(){
            //    self.status =isNaN(result)?2:result;

            return result;
    }),

    installFromZip: flow (function*(zipFile) {

        const data = new FormData() ;
        data.append('file', zipFile);
       
        // this can be customized by user..for now use the filename as package name.
        data.append('packagename', zipFile.name.split('.zip')[0]);
      
        const {
            data: result,
            status: responseStatus,
            statusText
          } = yield axios
            .post('/api/AppHost/versionInstallFromZip',data)
            .then(response => response)
            .catch(error => error.response);
    
            if(statusText==200) return result;
            else return [false,responseStatus];

    }),

    getInstalledVersions: flow (function*() {
         
        const {
            data: result,
            status: responseStatus,
            statusText
          } = yield axios
            .get('/api/AppHost/getInstalledVersions')
            .then(response => response)
            .catch(error => error.response);
    
            
         //self.status =isNaN(userObject)?1:userObject;

         //for now
         self.versions.clear();
         if(responseStatus==200  && result&& result.length){
             result.forEach(v => {
                self.versions.push(VersionModel.create({
                    name: v.name,
                   installDate: v.installDate,
                   desc: v.desc,
                 }))
             });
         }
         
    }),

    // getAvailableVersions: flow (function*() {
         
    //     const {
    //         data: result,
    //         status: responseStatus,
    //         statusText
    //       } = yield axios
    //         .get('/api/AppHost/getAvailableVersions')
    //         .then(response => response)
    //         .catch(error => error.response);
    
            
    //      //self.status =isNaN(userObject)?1:userObject;

    //      //for now
    //      self.availableVersions.clear();
    //      if(result){
    //          result.forEach(v => {
    //             self.availableVersions.push(AvailableVersionModel.create({
    //                 name: v.name,
    //                 releaseDate: v.releaseDate,
    //                desc: v.desc,
    //              }))
    //          });
    //      }
         
    // }),

    getUrls: flow (function*() {
         
        const {
            data: result,
            status: responseStatus,
            statusText
          } = yield axios
            .get('/api/AppHost/getSources')
            .then(response => response)
            .catch(error => error.response);
    
            
         //self.status =isNaN(userObject)?1:userObject;

        
         if(result && result.sourceUrl){
            self.websiteUrl=result.websiteUrl;
            self.sourceUrl=result.sourceUrl;
         }
         
    }),

  

}));

export default ApplicationModel;

