import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";

function Login(props) {
  const application = props.application;
  const versions = application.versions;
  const user = props.user;
  const t = props.t;

  console.log(application.versions.toJSON());

  // equivalent to componentDidMount due to 2nd arg as empty array
  useEffect(() => {
    // code to run on component mount
    user.validateToken();
  }, []);

  useEffect(() => {
    console.log("1 - version with sorting code");
    application.getInstalledVersions().then((r) => {
      setdestinationApp(versions.length > 0 ? versions.sort((a,b)=>{
        const isReversed = (a.name < b.name) ? 1: -1;
        return isReversed
      })[0].name : "Management");
    });
  }, [versions]);

  const [ready, setready] = useState(true);

  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [destinationApp, setdestinationApp] = useState("");
  const [message, setmessage] = useState("");

  const [about, setAbout] = useState("");

  useEffect(() => {
    if (destinationApp !== "" && destinationApp !== "Management")
      setAbout(
        versions.find((v) => {
          return v.name == destinationApp;
        }).desc
      );
    if (destinationApp === "Management") {
      setAbout(t('login.as_admin'));
    }
    return () => {
      setAbout(""); 
    };
  }, [destinationApp,application.language]);

  const tryLogin = () => {
    setready(false);
    setmessage('login.authenticate_start');
    props.user.login(username, password).then((result) => {
      if (result == true) {
        if (destinationApp === "Management") {
          if (props.user.isAdmin) {
            props.application.setStatus(2); // management screen
          } else {
            setmessage("login.as_admin2");
          }
        } else {
          setmessage("login.success", { app: destinationApp });
          // setTimeout(() => {
          document.location = document.location + destinationApp;
          // }, 5000);
        }
      } else setmessage("login.failed");
      setready(true);
    });
  };

  const redirectToApp = () => {
    setready(false);
    setmessage("login.authenticate_start");
    props.user.login(username, password).then((result) => {
      if (destinationApp === "Management") {
        if (props.user.isAdmin) {
          props.application.setStatus(2); // management screen
        } else {
          setmessage("login.as_admin2");
        }
      } else {
        setmessage("login.redirect", { app: destinationApp });
        // setTimeout(() => {
        document.location = document.location + destinationApp;
        // }, 5000);
      }

      setready(true);
    });
  };

  const logout = () => {
    props.user.logout().then(() => {
      props.application.setStatus(1);
      setmessage("login.logout_success");
    });
  };
  const check = true;
  return (
    <div className="login ">

      <h1> {t("login.title")} <p className="mini">{t("login.instructions")}</p></h1>
      
      {!user.loggedIn && (
        <form>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">{t("login.username")}</label>
            <input
              type="text"
              className="form-control"
              id="exampleInputEmail1"
              placeholder={t("login.username")}
              readOnly={!ready}
              onChange={(e) => setusername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputPassword1">{t("login.password")}</label>
            <input
              type="password"
              className="form-control"
              id="exampleInputPassword1"
              placeholder={t("login.password")}
              readOnly={!ready}
              onChange={(e) => setpassword(e.target.value)}
            />
          </div>
          <div className="form-group">
              <div className="text-center">
                <small className="mini">{t("login.app")}</small>
              </div>
              <div className="">
                <select className="form-control form-control-flat mini col-8 mx-auto" value={destinationApp} onChange={(e) => setdestinationApp(e.target.value)} >
                  { versions.sort((a,b)=>{
    const isReversed = (a.name < b.name) ? 1: -1;
    return isReversed
  }).map((o) => ( <option key={o.name} value={o.name} className="mini"> {o.name} </option> ))}
                  <option key={"Management"} value={"Management"} className="mini"> {t("login.app_management")}</option>
                </select>
              </div>
              <div className="text-center">
                <button type="button" className="btn btn-default bg-gradient-primary border-primary text-white mini" onClick={(e) => tryLogin()}>
                  {t("login.btn_login")} {!ready && <span className="busy-icon">.</span>}
                </button>
              </div>
            {/* <p className="mini text-center ">{about}</p> */}
          </div>
          <div className="form-group text-center">
            <span>{destinationApp=='Management'?t('login.as_admin'): t(message)} </span>
          </div>
        </form>
      )}
      {user.loggedIn && (
        <form>
          <div className="form-group">
            <label>
            {t("login.logged_in_as",{username:user.username})}
            </label>
          </div>

          <div className="form-group mb-5">
            <div className="login-button-row row">
              <label className="col-sm-3 mb-0 text-right">
                {t("login.app")}
              </label>
              <select
                className="form-control form-control-flat col-sm-6"
                value={destinationApp}
                onChange={(e) => setdestinationApp(e.target.value)}
              >
                {versions.sort((a,b)=>{
    const isReversed = (a.name < b.name) ? 1: -1;
    return isReversed
  }).map((o) => (
                  <option key={o.id} value={o.name}>
                    {o.name}
                  </option>
                ))}
                <option key={99} value={"Management"}>
                {t("login.app_management")}
                </option>
              </select>
              <button
                type="button"
                className="col-sm-3 btn btn-default bg-gradient-primary border-primary text-white"
                onClick={(e) => redirectToApp()}
              >
                 {t("login.proceed")}{!ready && <span className="busy-icon">.</span>}
              </button>
            </div>
            <p className="mini text-center">{about}</p>
            <div className="login-button-row row">
              <div className="col-sm-9"></div>
              <button
                type="button"
                className="col-sm-3 btn btn-default bg-gradient-primary border-primary text-white"
                onClick={(e) => logout()}
              >
                {t("login.btn_logout")} {!ready && <span className="busy-icon">.</span>}
              </button>
            </div>
          </div>
          <div className="form-group text-center d-block">
            <span>{t(message)}</span>
          </div>
        </form>
      )}
      
    </div>
  );
}

//export default Initialization;
export default observer(Login);
