import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import RootStore from './models/RootStore';
import ApplicationModel from './models/ApplicationModel';
//debugging tools
import { onPatch } from 'mobx-state-tree';
import makeInspectable from 'mobx-devtools-mst';


import UserModel from './models/UserModel';


import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_de from "./translations/de/common.json";
import common_en from "./translations/en/common.json";
import common_fr from "./translations/fr/common.json";
import common_it from "./translations/it/common.json";




const store = RootStore.create({
  application: ApplicationModel.create({
      name:"fakt|online",status:-1, versions:[],availableVersions:[]
      ,websiteUrl:"",sourceUrl:'',language: localStorage.getItem("lang")||'de'
    }),
  user: UserModel.create({
    id: 0,
    username: "",
    token: "",
    loggedIn: false,
    isAdmin:false,
  })
})

onPatch(store, patch => {
  console.log(patch)
})

makeInspectable(store);


i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: store.application.language,                              // language to use
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      de: {
          common: common_de
      },
      fr: {
          common: common_fr
      },
      it: {
          common: common_it
      },
  },
});

ReactDOM.render(
 /*  <React.StrictMode> */
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
            <App/>
        </I18nextProvider>
    </Provider>
  /* </React.StrictMode> */,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
