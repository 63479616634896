import { types,flow  } from "mobx-state-tree"
import axios from 'axios';


const UserModel = types.model({
    id: types.number ,
    username: types.string,
    token: types.string,
    loggedIn: types.boolean ,
    isAdmin:types.boolean ,
   
    
   
}).actions(self => ({

    setStatus(statusId) {
        self.loggedIn = statusId;
    },
    setToken(token) {
        self.token = token;
    },
    setUsername(username) {
        self.username = username;
    },
    setUserId(id) {
        self.id = id;
    },
    setIsAdmin(admin) {
        self.isAdmin = admin;
    },

    checkState: flow (function*() {
         
        const token= localStorage.getItem("token");
        if(!token) return false;

        if(token&&token.length>0){
           const result={ token, user: JSON.parse(localStorage.getItem("user")) };
           self.setToken(result.token);
           self.setUsername(result.user.username);
           self.setUserId( parseInt(result.user.id));
           self.setIsAdmin(result.user.is_admin==="1");
           self.setStatus(true);
        }
    }),
     
    validateToken: flow (function*() {
         

          
        const local_token=localStorage.getItem("token");
        if(!local_token) return false;
        
        axios.defaults.headers.common = {'Authorization': `${local_token}`}
        
        const {
            data: result,
            status: responseStatus,
            statusText
          } = yield axios
            .post('/api/Login/token',{})
            .then(response => response)
            .catch(error => error.response);
    
            //setTimeout(function(){
               // self.status =isNaN(result)?1:result;
              
               if(statusText!=200 && result.length==0){
                   localStorage.removeItem("token");
                    //localStorage.removeItem("user");
                    self.setToken('');
                  self.setUsername('');
                  self.setUserId(0);
                  self.setIsAdmin(false);
                  self.setStatus(false);
                   return false;
               }
               else{
                  self.setToken(result);
                
                  self.setStatus(true);
                    localStorage.setItem("token",result);
                 
                  return true;
               } 
    }),

    login: flow (function*(username , password) {
         
        const {
            data: result,
            status: responseStatus,
            statusText
          } = yield axios
            .post('/api/Login/authenticate',{
                username , password
            })
            .then(response => response)
            .catch(error => error.response);
    
            //setTimeout(function(){
               // self.status =isNaN(result)?1:result;
              
               if(result.token){
                  self.setToken(result.token);
                  self.setUsername(result.user.username);
                  self.setUserId( parseInt(result.user.id));
                  self.setIsAdmin(result.user.is_admin==="1");
                  self.setStatus(true);
                  axios.defaults.headers.common = {'Authorization': `bearer ${result.token}`}
                    localStorage.setItem("token",result.token);
                    localStorage.setItem("user",JSON.stringify(result.user));
                  return true;
               }else return false;
    }),

    
    logout: flow (function*() {
         
         localStorage.clear("token");
         localStorage.clear("user");
          
           self.setToken('');
           self.setUsername('');
           self.setUserId(0);
           self.setIsAdmin(false);
           self.setStatus(false);
       
    }),
  

}));

export default UserModel;

