import React, {Component} from 'react';
export class Browser extends Component {
    
    render(){
        return(
            <div class="bg-light p-2 mt-3 d-flex justify-content-center align-items-center mx-auto" style={{width:600}}>
                <small class="text-danger text-center" style={{fontSize:15}}>
                FAKT ONLINE wurde für Google Chrome (aktuellste Version: 75.0.3770.142) sowie Edge Chromium (Stand Jan. 2020) erstellt. Sie nutzen einen anderen Browser. Es ist nicht gewährleistet, dass die Seite mit anderen Browsern korrekt funktioniert.
                </small>
            </div>
        )
    }
}