import React ,{useEffect,useState} from 'react';
import { observer, inject } from 'mobx-react'; //These functions make our components observable and be able to use the store
import './App.css';
import Installer from './Installer';
import Initialization from './Initialization';
import Login from './Login';
import Error from './Error';
import VersionManager from './VersionManager';
import {Browser} from './Browser';

import {useTranslation} from "react-i18next";

import {detect} from 'detect-browser/es';



function App(props) {
  const { store } = props;
  const appName = store.application.name.split('|')

  const application=store.application;
  const appState =application.status;

  const {t, i18n} = useTranslation('common');

  //onMount
  useEffect(() => {
   application.setLanguage('de');// default to german
  }, [])


  useEffect(() => {
    // not ready for login
    if(appState==-1){

      application.checkState();
      
    }
    if(appState==1){

    
      store.user.checkState();
    }

    /* return () => {
      application.setStatus(0)
    } */
  }, [appState,application])

  const [showFlags, setShowFlags] = useState(false);
  const selectedFlag = application.language;
  const switchLanguage=(flag)=>{
      if(showFlags){
        i18n.changeLanguage(flag);
       application.setLanguage(flag);
       
      }

       setShowFlags(!showFlags);
       console.log([showFlags,flag])
  };
const show_browser_prompt =()=>{
  let detectObject = detect();
  let browser = detectObject.name;
  let version = detectObject.version;
  let os = detectObject.os;
  if(browser !='chrome' && browser !='edge-chromium'){
      return true
  }else{
      if( browser =='edge-chromium' && parseFloat(version) < 75){
          return true
          }
          return false
  }
};
  const isReady = store.application.status==3;
  return (
    <div className="App">
    
      <header className={"App-stage" +(isReady?' ready':'') }>
      <h1 className="App-name"><span>{appName[0]}</span> <span>{appName[1]}</span></h1>
      
      <div className="App-dialog">
      <div className="float-right btn-group-sm">
      
        {(selectedFlag=='de' || showFlags) && <button className={"btn btn-sm p-0 m-0 ml-1"} title={t('app.lang.in_de')} onClick={() => switchLanguage('de')}><img src="/assets/images/lang/de.png" width="25"></img></button>}
        {(selectedFlag=='fr' || showFlags) && <button className={"btn btn-sm p-0 m-0 ml-1"} title={t('app.lang.in_fr')} onClick={() => switchLanguage('fr')}><img src="/assets/images/lang/fr.png" width="25"></img></button>}
        {(selectedFlag=='it' || showFlags) && <button className={"btn btn-sm p-0 m-0 ml-1"} title={t('app.lang.in_it')} onClick={() => switchLanguage('it')}><img src="/assets/images/lang/it.png" width="25"></img></button>}
        {(selectedFlag=='en' || showFlags) && <button className={"btn btn-sm p-0 m-0 ml-1"} title={t('app.lang.in_en')} onClick={() => switchLanguage('en')}><img src="/assets/images/lang/en.png"  width="25"></img></button>}
    </div>
    
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}

        {/* <Installer></Installer> */}
        {appState==-1 && <Initialization application={store.application} t={t} ></Initialization>}
        {appState==0 && <Installer application={store.application}  t={t}></Installer>}
        {appState==1 && <Login application={store.application} user={store.user} t={t}></Login>}
        {show_browser_prompt()==true ? <Browser/> : ''}
        {appState==2 && <VersionManager application={store.application} user={store.user} t={t}></VersionManager>}
        {appState>99 && <Error code={appState}></Error>}
        </div>
      </header>
    </div>
  );
}

//export default App;
export default inject('store')(observer (App));
